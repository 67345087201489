import React from 'react';
import SEO from 'components/seo';
import Layout from 'components/Layout';
import Hero from 'components/Hero';
import ContactMe from 'components/ContactMe' ;
import Article from 'components/Article';
import { navigate } from 'gatsby-link';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Contact = () => {

  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <Layout pageName="contact_us">
      <SEO title="Contact Us" />
      <Hero 
        title="Contact Me"        
        ></Hero>
      <Article>
        <div className="connect">
            <div className="connect-text">
              <h3>Questions and Doubts</h3>
              <p> When in doubt, ask. Yes, if you have a pre sale question, or you have a question about the template that is not covered in the FAQ, or if you just want to say Hi, please use the contact form to reach me. I will get back to you at the earliest.</p> 
              <br/>
              <h3>Support</h3>
              <p>Please use the contact form to send in your support requests. Please select the appropriate subject title from the dropdown select box that matches your contact query. Please take a look at the Support Policy page to get a clear idea about what is covered in the product support.</p>
              <br/>
              <h3>Customisation requests</h3>
              <p>If you like to go for a custom design for your osTicket front-end rather than using the templates from themedost.com, please ask away. I will get back to you and we can work it out.</p>
              
            </div>
            <div className="connect-form">


            <form
              name="contact"
              method="post"
              action="/thankyou/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>

          <div className="field">
              <label>Name</label>
              <input name="name" type="text" required onChange={handleChange}/>
          </div>
          <div className="field">
              <label>Email</label>
              <input name="email" type="email" required onChange={handleChange}/>
          </div>
          <div className="field">
              <label>Subject</label>
              <select name="subject" onChange={handleChange}>
                  <option value="themedost-contact">Select a subject</option>
                  <option value="presale-question">Presale question</option>
                  <option value="custom-theme">Custom theme request</option>
                  <option value="support-request">Support request</option>
                  <option value="testimonial">Testimonial</option>
              </select>
          </div>
          <div className="field">
              <label>Message</label>
              <textarea name="message" cols="30" rows="10" onChange={handleChange}></textarea>
          </div>
          
          <div className="field">
              <button className="submit" type="submit">Submit</button>
          </div>
      </form>
            </div>
        </div>        
      </Article>
      <ContactMe></ContactMe>
    </Layout>
  );
};

export default Contact;